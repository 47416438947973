// LIVE
// export const BASEURL = "https://miraj-api.binarynumbers.io/api";
// export const IMAGEURL = "https://miraj-api.binarynumbers.io";

// DEV
//AJAY Mac - http://127.0.0.1:8003/api
//Singhar IP - http://192.168.1.204:8003/api
//export const BASEURL = "http://localhost:8003/api";
//export const BASEURL = "http://127.0.0.1:8003/api";


export const BASEURL = process.env.VUE_APP_BASEURL;
export const FILESURL = process.env.VUE_APP_FILESURL;
export const API_VERSION = "v1.0";

export const STRIPE_PUB_KEY = "pk_test_51KWhBAFLwZRNwlE3qcalhNmlssJeO8UD2PrgkcJSImboWFWohcQsXkgBtnHpNFYtvzQKmESJ5OogwgR8m8TGADpO00Xe8OyJGP";
