import axios from "axios";
import Vue from "vue";
import { BASEURL } from "@/config/index";
import cookieType from "@/config/cookieType";
import router from "@/router";
// import notification from "ant-design-vue/es/notification";
import store from "@/store";

export default () => {
  const instance = axios.create({
    baseURL: BASEURL,
  });

  // Vue.cookie.get(cookieType.MCHASHID)
  //     ? (instance.defaults.headers.common["Authorization"] = Vue.cookie.get(
  //         cookieType.MCHASHID
  //     ))
  //     : undefined;
  
  instance.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

  instance.defaults.headers.common = {
      'X-Requested-With': 'XMLHttpRequest',
      'Access-Control-Allow-Origin': '*'
  };

  let token = localStorage.getItem("token");
  if (token != "") {
    instance.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
    instance.defaults.headers.common["Authorization"] = token;
  }

  instance.interceptors.response.use(
    function (response) {
      // Do something with response data
      return response;
    },
    function (error) {
      console.log({ networkError: error });
      // Do something with response error
      // if (!error["response"]) {
      //   // showErrorMessage("Your authorization token is invalid or expired");
      //   // redirect to auth page or login again
      //   // router.push({
      //   //   path: "/login",
      //   // });
      //   return Promise.reject(error);
      // }
      // else 
      if (error.response.status === 403) {
        localStorage.removeItem("token");
        showErrorMessage("Your authorization token is invalid or expired");
        // redirect to auth page or login again
        router.push({
          path: "/onboarding",
        });
      }
      // else if (error.response.status == 401) {
      //   showErrorMessage(
      //     "Something went wrong. kindly contact your administrator"
      //   );
      // } else {
      //   // showErrorMessage(
      //   //   "Something went wrong. kindly contact your administrator"
      //   // );
      // }
      return Promise.reject(error.response);
    }
  );
  return instance;
};

const showErrorMessage = (message) => {
  console.log("Error ", message);
  // notification.error({
  //   message: "Oops!",
  //   description: message
  // });
};
