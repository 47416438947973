import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  //base: process.env.BASE_URL,
  base: '/sell',
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      name: "analytics",
      component: () => import("@/views/pages/Ecommerce/Ecommerce.vue"),
      meta: {
        pageTitle: "Dashboard",
        breadcrumb: [
          {
            text: "Dashboard",
            active: true,
          },
        ],
      },
    },
    {
      path: "/qr-invoice",
      name: "qr-invoice",
      component: () =>
        import("@/views/pages/ProductList/QRInvoice.vue"),
      meta: {
        pageTitle: "Product Details",
        breadcrumb: [
          {
            text: "Product Details",
            active: true,
          },
        ],
      },
    },
    {
      path: "/product-details",
      name: "product-details",
      component: () =>
        import("@/views/pages/SellerItems/ItemDetailsTimeLine.vue"),
      meta: {
        pageTitle: "Product Details",
        breadcrumb: [
          {
            text: "Product Details",
            active: true,
          },
        ],
      },
    },
    {
      path: "/product_list",
      name: "product_list",
      component: () => import("@/views/pages/SellerItems/ItemListLayout.vue"),
      meta: {
        pageTitle: "Item List",
        breadcrumb: [
          {
            text: "Item List",
            active: true,
          },
        ],
      },
    },
    {
      path: "/status-table",
      name: "status-table",
      component: () => import("@/views/pages/ProductList/DashboardStatusTableList.vue"),
      meta: {
        pageTitle: "Status - Item List",
        breadcrumb: [
          {
            text: "Item List",
            active: true,
          },
        ],
      },
    },

    {
      path: "/address",
      name: "address",
      component: () => import("@/views/pages/Address/AddressList.vue"),
      meta: {
        pageTitle: "address List",
        breadcrumb: [
          {
            text: "address List",
            active: true,
          },
        ],
      },
    },
    {
      path: "/notifications",
      name: "notifications",
      component: () => import("@/views/pages/Notifications/Notifications.vue"),
      meta: {
        pageTitle: "Notifications",
        breadcrumb: [
          {
            text: "Notifications",
            active: true,
          },
        ],
      },
    },

    {
      path: "/posts",
      name: "posts",
      component: () => import("@/views/pages/Posts/Posts.vue"),
      meta: {
        pageTitle: "Posts",
        breadcrumb: [
          {
            text: "Posts",
            active: true,
          },
        ],
      },
    },

    {
      path: "/gallery",
      name: "gallery",
      component: () => import("@/views/pages/Gallery/Gallery.vue"),
      meta: {
        pageTitle: "Gallery",
        breadcrumb: [
          {
            text: "Gallery View",
            active: true,
          },
        ],
      },
    },
    {
      path: "/sell_refurbish",
      name: "sell_refurbish",
      component: () =>
        import("@/views/pages/SellerItems/ItemForm.vue"),
      meta: {
        pageTitle: "Create Item",
        breadcrumb: [
          {
            text: "Sell / Refurbish",
            active: true,
          },
        ],
      },
    },

    {
      path: "/account-setting",
      name: "account-setting",
      component: () =>
        import("@/views/pages/ProfileAccountSettings/AccountSetting.vue"),
      meta: {
        pageTitle: "Account Settings",
        breadcrumb: [
          {
            text: "Account Settings",
            active: true,
          },
        ],
      },
    },

    {
      path: "/help-support",
      name: "help-support",
      component: () => import("@/views/pages/HelpSupport/HelpSupport.vue"),
      //component: () => import("@/views/apps/email/Email.vue"),
      meta: {
        pageTitle: "Help & Support",
        breadcrumb: [
          {
            text: "Help & Support",
            active: true,
          },
        ],
      },
    },

    {
      path: "/product-promotion",
      name: "product-promotion",
      //component: () => import("@/views/pages/HelpSupport/HelpSupport.vue"),
      component: () =>
        import("@/views/pages/ProductPromotion/ProductPromotion.vue"),
      meta: {
        pageTitle: "Product Promotion",
        breadcrumb: [
          {
            text: "Product Promotion",
            active: true,
          },
        ],
      },
    },

    {
      path: "/earn-credit",
      name: "earn-credit",
      component: () => import("@/views/pages/EarnCredit/EarnCredit.vue"),
      meta: {
        pageTitle: "Earn & Credit",
        breadcrumb: [
          {
            text: "Earn & Credit",
            active: true,
          },
        ],
      },
    },

    // {
    //   path: "/invoice-list",
    //   name: "invoice-list",
    //   component: () => import("@/views/pages/Invoice/invoice-list/InvoiceList.vue"),
    //   meta: {
    //     pageTitle: "Invoices",
    //     breadcrumb: [
    //       {
    //         text: "Invoices",
    //         active: true,
    //       },
    //     ],
    //   },
    // },

    // {
    //   path: "/apps-invoice-add",
    //   name: "apps-invoice-add",
    //   component: () => import("@/views/pages/Invoice/invoice-add/InvoiceAdd.vue"),
    //   meta: {
    //     pageTitle: "Invoices",
    //     breadcrumb: [
    //       {
    //         text: "Invoices",
    //         active: true,
    //       },
    //     ],
    //   },
    // },

    {
      path: "/quotation_details",
      name: "quotation_details",
      component: () => import("@/views/pages/ProductList/QuotationDetails.vue"),
      meta: {
        pageTitle: "Quotation Details",
        breadcrumb: [
          {
            text: "Accept / Reject",
            active: true,
          },
        ],
      },
    },

    {
      path: "/contact-us",
      name: "contact-us",
      component: () => import("@/views/pages/ContactUs/ContactUs.vue"),
      meta: {
        contentRenderer: "sidebar-left",
        contentClass: "chat-application",
      },
    },


    // ADMIN ROUTES - STARTS

    {
      path: "/dashboard",
      name: "dashboard",
      component: () => import("@/views/pages/Ecommerce/Ecommerce.vue"),
      meta: {
        pageTitle: "Dashboard",
        breadcrumb: [
          {
            text: "Dashboard",
            active: true,
          },
        ],
      },
    },

    {
      path: "/admin-items",
      name: "admin-items",
      component: () => import("@/views/pages/Admin/ItemsList/MainItemLayout.vue"),
      meta: {
        pageTitle: "Items",
        breadcrumb: [
          {
            text: "Items",
            active: true,
          },
        ],
      },
    },

    {
      path: "/admin-user-management",
      name: "admin-user-management",
      component: () => import("@/views/pages/Admin/UserManagement/UserList.vue"),
      meta: {
        pageTitle: "Users",
        breadcrumb: [
          {
            text: "Users",
            active: true,
          },
        ],
      },
    },

    // Masters
    {
      path: "/service-list",
      name: "service-list",
      component: () =>
        import("@/views/pages/Admin/Masters/Services/ServiceList.vue"),
      meta: {
        pageTitle: "Services",
        breadcrumb: [
          {
            text: "Services",
            active: true,
          },
        ],
      },
    },
    {
      path: "/service-form/:service_id",
      name: "service-form",
      component: () =>
        import("@/views/pages/Admin/Masters/Services/ServiceForm.vue"),
      meta: {
        pageTitle: "Service",
        breadcrumb: [
          {
            text: "Service",
            active: true,
          },
        ],
      },
    },
    {
      path: "/ItemType-list/:tag_type_name",
      name: "ItemType-list",
      component: () =>
        import("@/views/pages/Admin/Masters/Tag/tagsList.vue"),
      meta: {
        pageTitle: "Item Type",
      },
    },
    {
      path: "/ItemType-form/:tag_id/:tag_type_name",
      name: "ItemType-form",
      component: () =>
        import("@/views/pages/Admin/Masters/Tag/tagForm.vue"),
      meta: {
        pageTitle: "Item Type",
      },
    },
    {
      path: "/Designer-list/:tag_type_name",
      name: "Designer-list",
      component: () =>
        import("@/views/pages/Admin/Masters/Tag/tagsList.vue"),
      meta: {
        pageTitle: "Designer",
      },
    },
    {
      path: "/Designer-form/:tag_id/:tag_type_name",
      name: "Designer-form",
      component: () =>
        import("@/views/pages/Admin/Masters/Tag/tagForm.vue"),
      meta: {
        pageTitle: "Designer",
      },
    },
    {
      path: "/Condition-list/:tag_type_name",
      name: "Condition-list",
      component: () =>
        import("@/views/pages/Admin/Masters/Tag/tagsList.vue"),
      meta: {
        pageTitle: "Condition",
      },
    },
    {
      path: "/Condition-form/:tag_id/:tag_type_name",
      name: "Condition-form",
      component: () =>
        import("@/views/pages/Admin/Masters/Tag/tagForm.vue"),
      meta: {
        pageTitle: "Condition",
      },
    },
    {
      path: "/Material-list/:tag_type_name",
      name: "Material-list",
      component: () =>
        import("@/views/pages/Admin/Masters/Tag/tagsList.vue"),
      meta: {
        pageTitle: "Material",
      },
    },
    {
      path: "/Material-form/:tag_id/:tag_type_name",
      name: "Material-form",
      component: () =>
        import("@/views/pages/Admin/Masters/Tag/tagForm.vue"),
      meta: {
        pageTitle: "Material",
      },
    },
    {
      path: "/IndianBottomWaist-list/:tag_type_name",
      name: "IndianBottomWaist-list",
      component: () =>
        import("@/views/pages/Admin/Masters/Tag/tagsList.vue"),
      meta: {
        pageTitle: "Indian Bottom Waist",
      },
    },
    {
      path: "/IndianBottomWaist-form/:tag_id/:tag_type_name",
      name: "IndianBottomWaist-form",
      component: () =>
        import("@/views/pages/Admin/Masters/Tag/tagForm.vue"),
      meta: {
        pageTitle: "Indian Bottom Waist",
      },
    },
    {
      path: "/IndianBust-list/:tag_type_name",
      name: "IndianBust-list",
      component: () =>
        import("@/views/pages/Admin/Masters/Tag/tagsList.vue"),
      meta: {
        pageTitle: "Indian Bust",
      },
    },
    {
      path: "/IndianBust-form/:tag_id/:tag_type_name",
      name: "IndianBust-form",
      component: () =>
        import("@/views/pages/Admin/Masters/Tag/tagForm.vue"),
      meta: {
        pageTitle: "Indian Bust",
      },
    },
    {
      path: "/IndianTopHip-list/:tag_type_name",
      name: "IndianTopHip-list",
      component: () =>
        import("@/views/pages/Admin/Masters/Tag/tagsList.vue"),
      meta: {
        pageTitle: "Indian Top Hip",
      },
    },
    {
      path: "/IndianTopHip-form/:tag_id/:tag_type_name",
      name: "IndianTopHip-form",
      component: () =>
        import("@/views/pages/Admin/Masters/Tag/tagForm.vue"),
      meta: {
        pageTitle: "Indian Top Hip",
      },
    },
    {
      path: "/WesternBottom-list/:tag_type_name",
      name: "WesternBottom-list",
      component: () =>
        import("@/views/pages/Admin/Masters/Tag/tagsList.vue"),
      meta: {
        pageTitle: "Western Bottom",
      },
    },
    {
      path: "/WesternBottom-form/:tag_id/:tag_type_name",
      name: "WesternBottom-form",
      component: () =>
        import("@/views/pages/Admin/Masters/Tag/tagForm.vue"),
      meta: {
        pageTitle: "Western Bottom",
      },
    },
    {
      path: "/WesternTop-list/:tag_type_name",
      name: "WesternTop-list",
      component: () =>
        import("@/views/pages/Admin/Masters/Tag/tagsList.vue"),
      meta: {
        pageTitle: "Western Top",
      },
    },
    {
      path: "/WesternTop-form/:tag_id/:tag_type_name",
      name: "WesternTop-form",
      component: () =>
        import("@/views/pages/Admin/Masters/Tag/tagForm.vue"),
      meta: {
        pageTitle: "Western Top",
      },
    },
    {
      path: "/currency-list",
      name: "currency-list",
      component: () =>
        import("@/views/pages/Admin/Masters/Currency/currencyList.vue"),
      meta: {
        pageTitle: "Currency",
        breadcrumb: [
          {
            text: "Currency",
            active: true,
          },
        ],
      },
    },
    {
      path: "/currency-form/:c_id",
      name: "currency-form",
      component: () =>
        import("@/views/pages/Admin/Masters/Currency/currencyForm.vue"),
      meta: {
        pageTitle: "Currency",
        breadcrumb: [
          {
            text: "Currency",
            active: true,
          },
        ],
      },
    },
    {
      path: "/admin-user-details",
      name: "admin-user-details",
      component: () => import("@/views/pages/Admin/UserManagement/UserDetails.vue"),
      meta: {
        pageTitle: "Users Details",
        breadcrumb: [
          {
            text: "Users Details",
            active: true,
          },
        ],
      },
    },

    {
      path: "/admin-user-form/:user_id",
      name: "admin-user-form",
      component: () => import("@/views/pages/Admin/UserManagement/UserForm.vue"),
      meta: {
        pageTitle: "User",
        breadcrumb: [
          {
            text: "User",
            active: true,
          },
        ],
      },
    },

    {
      path: "/admin-account-settings",
      name: "admin-account-settings",
      component: () => import("@/views/pages/Admin/Settings/AdminSettingMainLayout.vue"),
      meta: {
        pageTitle: "Admin Settings",
        breadcrumb: [
          {
            text: "Admin Settings",
            active: true,
          },
        ],
      },
    },

    {
      path: "/admin-notifications",
      name: "admin-notifications",
      component: () => import("@/views/pages/Admin/Notifications/AdminNotifications.vue"),
      meta: {
        pageTitle: "Admin Notifications",
        breadcrumb: [
          {
            text: "Admin Notifications",
            active: true,
          },
        ],
      },
    },
    {
      path: "/about-us",
      name: "About us",
      component: () => import("@/views/pages/templates/Aboutus.vue"),
      meta: {
        pageTitle: "Aboutus",
        layout: "full",
        breadcrumb: [
          {
            text: "About us",
            active: true,
          },
        ],
      },
    },
    {
      path: "/services",
      name: "Services",
      component: () => import("@/views/pages/templates/Services.vue"),
      meta: {
        pageTitle: "Services",
        layout: "full",
        breadcrumb: [
          {
            text: "Services",
            active: true,
          },
        ],
      },
    },
    {
      path: "/careers",
      name: "Careers",
      component: () => import("@/views/pages/templates/Careers.vue"),
      meta: {
        pageTitle: "Careers",
        layout: "full",
        breadcrumb: [
          {
            text: "Careers",
            active: true,
          },
        ],
      },
    },
    // ADMIN ROUTES - ENDS

    {
      path: "/forgot-password",
      name: "forgot-password",
      component: () => import("@/views/pages/ForgotPassword/ForgotPassword.vue"),
      meta: {
        layout: "full",
      },
    },

    /* {
      path: "/login",
      name: "login",
      component: () => import("@/views/pages/Login/Login.vue"),
      meta: {
        layout: "full",
      },
    }, */
    /* {
      path: "/register",
      name: "register",
      component: () => import("@/views/pages/Register/Register.vue"),
      meta: {
        layout: "full",
      },
    }, */
    {
      path: "/onboarding",
      name: "onboarding",
      component: () => import("@/views/pages/onboarding/onboarding.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/error-404",
      name: "error-404",
      component: () => import("@/views/error/Error404.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "*",
      redirect: "error-404",
    },
  
  ],
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

router.beforeEach((to, from, next) => {
  //to.path != "/login" && to.path != "/register" && 
  if (to.path != "/forgot-password" && to.path != "/about-us" && to.path != "/services" && to.path != "/onboarding") {
    // check token
    if (!localStorage.getItem("token")) {
      console.log("Un auth");
      router.push("/onboarding");
    }
  }

  next();
});

export default router;
