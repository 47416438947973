import axiosInstance from './Api';

export default {

    async getCurrency() {

        let response = await axiosInstance().get(`/home/currency`);

        return response;
    },

    async getServices() {

        let response = await axiosInstance().get(`/home/services`);

        return response;
    },

}