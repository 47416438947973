import UserManagementServices from "@/apiServices/UserManagementServices";


export default {
    namespaced: true,
    state: {
        user_details: {},
    },
    getters: {
        getUserDetails: state => state.user_details,
    },
    mutations: {
        SET_USER_DETAILS(state, val) {
            state.user_details = val
        },
    },
    actions: {
        onGetUserProfile: async ({ dispatch, commit, state }, { ...payload }) => {
            await commit('SET_USER_DETAILS', {});
            UserManagementServices.getUserProfile()
                .then(async (response) => {
                    console.log(response);
                    if (response.data.status) {
                        await commit('SET_USER_DETAILS', response.data.data)
                        localStorage.setItem(
                            "userInfo",
                            JSON.stringify(response.data.data)
                        );
                    } else {
                        console.log(response.data);
                    }
                })
                .catch(err => {
                    console.log('Error in getting assigned students ', err);
                })
        }
    },
}
