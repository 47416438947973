import HomeServices from "@/apiServices/HomeServices";

export default {
    namespaced: true,
    state: {
        currency: [],
        services: [],
    },
    getters: {
        getCurrency: state => state.currency,
        getServices: state => state.services
    },
    mutations: {
        SET_CURRENCY(state, val) {
            state.currency = val
        },
        SET_SERVICES(state, val) {
            state.services = val
        }
    },
    actions: {
        setCurrency: async ({ dispatch, commit, state }, { ...payload }) => {
            try {
                await commit('SET_CURRENCY', []);
                const response = await HomeServices.getCurrency();
                if (response.data.status) {
                    await commit('SET_CURRENCY', response.data.data)
                } else {
                    console.log('Get Currency Status false');
                }
            } catch (err) {
                console.log('Error in getting currency ', err);
            }
        },
        setServices: async ({ dispatch, commit, state }, { ...payload }) => {
            try {
                await commit('SET_SERVICES', []);
                const response = await HomeServices.getServices();
                if (response.data.status) {
                    await commit('SET_SERVICES', response.data.data)
                } else {
                    console.log('Get Currency Status false');
                }
            } catch (err) {
                console.log('Error in getting currency ', err);
            }
        },
    },
}
